import {ReactComponent as SmartPhone2} from "../../assets/smartphone-2.svg";
import {ReactComponent as SmartPhone} from "../../assets/smartphone.svg";
import {ReactComponent as Mail} from "../../assets/mail.svg";
import './Contacts.css';

function Contacts() {
    return (
        <div className="layoutContacts" id="contacts">
            <div className="block_name mb-4">
                <span className="name">Контакты</span>
                <div className="delimiter"></div>
                <SmartPhone2 className="smartPhone"/>
            </div>
            <div className="contacts_layout">
                <div className="contacts_layout_left">
                    <div className="contacts_layout_left_header">Как нам позвонить</div>
                    <div className="contacts_layout_left_info">
                        <SmartPhone className="contacts_layout_left_info_smartPhone"/>
                        <div className="delimiter"></div>
                        <div className="contacts_layout_left_info_phone" onClick={handlerPhoneClick}>+7 (905)
                            904-99-11
                        </div>
                    </div>
                </div>
                <div className="contacts_layout_right">
                    <div className="contacts_layout_right_header">Как нам написать</div>
                    <div className="contacts_layout_right_info">
                        <Mail className="contacts_layout_right_info_mailLogo"/>
                        <div className="delimiter"></div>
                        <div className="contacts_layout_right_info_mail"
                             onClick={handlerMailClick}>sib_elektrod@mail.ru
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function handlerMailClick() {
    window.open('mailto:sib_elektrod@mail.ru');
}


function handlerPhoneClick() {
    window.open('tel:+79059049911');
}

export default Contacts