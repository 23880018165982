import data from "../../../public/assets/data.json";
import './TabContent.css';
import Product from "./Product";
import ModalShowStoreItem from "./ModalShowStoreItem";
import {useState} from "react";

function TabContent({type, filters}) {
    const [modalShow, setModalShow] = useState(false);
    const [ModalData, setModalData] = useState(
        {
            "name": "None",
            "description": "None",
            "logoPath": "error.webp"
        }
    );
    let jsonData = [];
    try {
        jsonData = data[type]["goods"];
    } catch (e) {
        console.log('No data for: ' + type, e)
    }
    for (let filter in filters) {
        if (filters[filter].selected.length === 0) continue;
        jsonData = jsonData.filter(item => {
            if (item[filter] instanceof Array) {
                return (item[filter] !== undefined && item[filter].some(el => filters[filter].selected.includes(el)));
            } else {
                return (item[filter] !== undefined && filters[filter].selected.includes(item[filter]));
            }
        });
    }
    return (
        <div className="tab">
            <ModalShowStoreItem
                show={modalShow}
                onHide={() => setModalShow(false)
                }
                data={ModalData}
            />
            {
                jsonData.length === 0 &&
                <div className="notingFound">Ничего не удалось найти по заданным параметрам 😥</div>
            }
            {
                jsonData.map((item, index) =>
                    <Product key={index} item={item} setModalShow={setModalShow} setModalData={setModalData}/>
                )
            }
        </div>
    )
}

export default TabContent