import {Accordion} from "react-bootstrap";
import './Filters.css';

function Filters({filters, setFilters}) {
    const filtersList = Object.keys(filters);
    return (
        <Accordion alwaysOpen>
            {
                filtersList && filtersList.map((filter, index) => {
                        const filterData = filters[filter];
                        return (
                            <Accordion.Item key={index} eventKey={index.toString()}>
                                <Accordion.Header>{filterData.name}</Accordion.Header>
                                <Accordion.Body className="filterList">
                                    {
                                        filterData.variants.map((item, index) => {
                                                return (
                                                    <div key={index} className="filter">
                                                        <input type="checkbox" id={item.toString()} name={item.toString()}
                                                               value={item.toString()}
                                                               checked={filterData.selected.includes(item)}
                                                               onChange={(x) => handleChangeFilter(filter, x, setFilters)}/>
                                                        <label htmlFor={item.toString()}>{item}</label>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                )
            }
        </Accordion>
    );
}

function handleChangeFilter(filter, event, setFilters) {
    setFilters(prevState => {
            const newFilters = {...prevState};
            const filterData = newFilters[filter];
            if (event.target.checked) {
                filterData.selected.push(event.target.value);
            } else {
                filterData.selected = filterData.selected.filter(item => item !== event.target.value);
            }
            return newFilters;
        }
    );
}

export default Filters