import logo from '../../assets/logo.webp'
import './Header.css'
import {ReactComponent as Clock} from '../../assets/clock.svg';
import {ReactComponent as SmartPhone} from '../../assets/smartphone.svg';
import {ReactComponent as Map} from '../../assets/map.svg';
import {Palette} from "../Services";

function Header() {
    return (
        <div className="header sticky-top">
            <div className="header_left el">
                <span className="header_button" onClick={() => handleScrollToStore("store")}>Товары</span>
                <span className="delimiter"></span>
                <span className="header_button" onClick={() => handleScrollToStore("contacts")}>Контакты</span>
            </div>
            <div className="header_logo el" onClick={() => handleScrollToStore("carousel")}>
                <img className="logo" src={logo} alt="Company logo" loading="lazy"/>
            </div>
            <div className="header_right el">
                <div className="schedule">
                    <Clock className="clock" alt="clock" fill={Palette.primaryColor}/>
                    <span className="delimiter"></span>
                    <div>
                        <strong>СБ 10.00 - 15.00</strong><br/>
                        <strong>Пн-Пт 08.30 - 17.00</strong>
                    </div>
                </div>
                <div className="callUs">
                    <SmartPhone className="smartphone" alt="smartphone" fill={Palette.primaryColor}/>
                    <span className="delimiter"></span>
                    <div>
                        <span>Свяжитесь с нами</span>
                        <br/>
                        <a href="tel:+79059049911">
                            <strong>+7 905 904 99 11</strong>
                        </a>
                        <br/>
                        <a href="mailto:sib_elektrod@mail.ru" className="text-uppercase">
                            <strong>sib_elektrod@mail.ru</strong>
                        </a>
                    </div>
                </div>
                <div className="location" onClick={() => handleScrollToStore("map")}>
                    <Map className="map" alt="map"/>
                    <span className="delimiter"></span>
                    <div>
                        <span>Новокузнецк</span>
                        <br/>
                        <strong>ул. Ярославская, 21</strong>
                    </div>
                    <span className="delimiter"></span>
                </div>
            </div>
        </div>
    )
}

function handleScrollToStore(to) {
    document.getElementById(to).scrollIntoView({behavior: "smooth"});
}

export default Header