import './App.css';
import React from "react";
import Header from "./components/header/Header";
import CarouselSlider from "./components/carousel/CarouselSlider";
import Goods from "./components/goods/Goods";
import Contacts from "./components/contacts/Contacts";
import Map from "./components/Map/Map";
import Footer from "./components/footer/Footer";

function App() {
    return (
        <div className="main">
            <Header/>
            <CarouselSlider/>
            <Goods/>
            <Contacts/>
            <Map/>
            <Footer/>
        </div>
    );
}

export default App;