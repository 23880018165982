import './Map.css';
import {ReactComponent as MapLogo} from "../../assets/map-2.svg";

function Map() {
    return (
        <div className="layoutMap" id="map">
            <div className="block_name mb-1">
                <span className="name">Мы на карте</span>
                <div className="delimiter"></div>
                <MapLogo className="mapLogo"/>
            </div>
            <div className="mapAddress">
                Адрес: г. Новокузнецк, ул. Ярославская, 21
            </div>
            <div>
                <iframe width="100%"
                        height="580px"
                        title="GoogleMap"
                        loading="lazy"
                        src="https://www.google.com/maps/embed/v1/place?q=Сибэлектрод,+Ярославская+улица,+Новокузнецк,+Россия&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"/>
            </div>
        </div>
    )
}

export default Map