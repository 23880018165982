import {Carousel} from "react-bootstrap";
import img1 from '../../assets/carousel/carousel_6.webp';
import img2 from '../../assets/carousel/carousel_7.webp';
import img3 from '../../assets/carousel/carousel_8.webp';
import img4 from '../../assets/carousel/carousel_9.webp';
import './CarouselSlider.css';
import CarouselImage from "./CarouselImage";

function CarouselSlider() {
    return (
        <Carousel className="carouselSlider" interval={4000} id="carousel">
            <Carousel.Item>
                <CarouselImage src={img1}/>
                <Carousel.Caption className="caption_dark">
                    <h3>Сварочные материалы, на которые можно положиться.</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselImage src={img2}/>
                <Carousel.Caption className="caption_dark">
                    <h3>Объединяем металлы, создаем будущее вместе</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselImage src={img3}/>
                <Carousel.Caption className="caption_dark">
                    <h3>Доверьтесь профессионалам сварки</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselImage src={img4}/>
                <Carousel.Caption className="caption_dark">
                    <h3>Раскройте потенциал металла с нами</h3>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselSlider