import './Footer.css';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <div className="footer">
            ООО "СибЭлектрод" © {year} Все права защищены.
        </div>
    )
}

export default Footer