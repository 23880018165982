import {ReactComponent as Cart} from "../../assets/cart.svg";
import './Goods.css';
import {Button, Tab, Tabs} from "react-bootstrap";
import TabContent from "./TabContent";
import {useEffect, useState} from "react";
import Filters from "./Filters";
import {onSelectTab, TabList} from '../Services'

function Goods() {
    const [filters, setFilters] = useState({});
    const tabList = TabList;
    const defaultTabName = tabList[0].name ?? "None";
    useEffect(() => {
        onSelectTab(defaultTabName, setFilters);
        openFilters();
    }, [defaultTabName]);
    return (
        <div className="layoutStore" id="store">
            <div className="block_name mb-4">
                <span className="name">Товары </span>
                <div className="delimiter"></div>
                <Cart className="cart"/>
            </div>
            <div className="goods_place">
                <div className="goods_place_left">
                    <Tabs defaultActiveKey={defaultTabName} justify className="tabs"
                          onSelect={(name) => onSelectTab(name, setFilters)}>
                        {
                            tabList && tabList.map((tab, index) => {
                                    return (
                                        <Tab key={index} eventKey={tab.name} title={tab.title}>
                                            <TabContent type={tab.name} filters={filters}/>
                                        </Tab>
                                    )
                                }
                            )
                        }
                    </Tabs>
                </div>
                <div className="goods_place_right">
                    <Filters setFilters={setFilters} filters={filters}/>
                    <div className="reset-button">
                        <Button variant="outline-light" size="lg"
                                onClick={() => handleResetFilters(filters, setFilters)}>Сбросить</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function handleResetFilters(filters, setFilters) {
    const newFilters = {...filters};
    for (let filter in newFilters) {
        filters[filter].selected = [];
    }
    setFilters(newFilters);
}

async function openFilters() {
    await new Promise(r => setTimeout(r, 100));
    for (const item of document.querySelectorAll('.accordion-button.collapsed')) {
        item.click();
        await new Promise(r => setTimeout(r, 100));
    }
}

export default Goods