import './CarouselImage.css';

function CarouselImage({src}) {
    return (
        <div className="carouselImage">
            <img src={src} alt="carousel item" loading="eager"/>
        </div>
    );
}

export default CarouselImage
